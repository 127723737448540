.media-container{
	position: relative;
	min-height: 200px;
}
.media-container-mobile{
	position: relative;
}
.media-play{
	display: flex;
	justify-content: center;
	overflow: hidden;
}
.play-media-overlay{
	background-color: rgba(196, 196, 196, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
video {
  width: 100% !important;
  height: auto !important;
}

.text-reject-vehicle {
	color: #8C8C8F;
}

.reject-vehicle {
	background-color: #FF0000;
	height: 8px;
	width: 100%;
}

.approved-vehicle {
	background-color: #28A038;
	height: 8px;
	width: 100%;
}

.btn-reject {
	position: relative;
	z-index: 2;
	background-color: #FF0000;
	padding: 0.25rem 1.25rem;
	color: white;
	border-radius: 24px;
	opacity: 1 !important;
	text-align: center;
	margin-bottom: 0.25rem;
}

.btn-approved {
	background-color: #28A038;
	padding: 0.25rem 1.25rem;
	color: white;
	border-radius: 24px;
	opacity: 1 !important;
	text-align: center;
	margin-bottom: 0.25rem;
}

.blur {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	background: white;
	opacity: 0.5;
}

@media screen and (max-width: 768px) {
	.btn-reject {
		width: fit-content;
		padding: 0.25rem 1rem;
		margin: 1rem 0;
	}

	.btn-approved {
		width: fit-content;
		padding: 0.25rem 1rem;
		margin: 1rem 0;
	}
}

.approve-or-reject-desktop {
	width: 50%;
	margin: 0 auto;
	@media screen and (max-width: 992px) {
		width: 70%;
	}
}

.service-desktop {
	.total {
		font-size: 0.75rem;
		color: #8C8C8F;
	}
}
.service-mobile {
	.name {
		font-size: 0.85rem;
	}
	.total {
		font-size: 0.85rem;
		color: #8C8C8F;
	}
}