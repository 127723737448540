.form-toggle-group.form-group {
  margin-bottom: 0rem;
  .form-toggle {
    display: flex;
    align-items: center;
    margin-bottom: unset;
    .form-label {
      color: #353535;
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0;
      margin-right: 1.25rem;
      order: 1;
    }
    .switch {
      position: relative;
      width: 60px;
      height: 2.25rem;
      order: 2;
    
      input {
        display: none;
      }
    
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
    
      .slider:before {
        position: absolute;
        content: "";
        height: 1.75rem;
        width: 1.75rem;
        left: 0.25rem;
        bottom: 0.25rem;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
    
      input:checked+.slider {
        background-color: $bright-blue;
      }
    
      input:focus+.slider {
        box-shadow: 0 0 1px $bright-blue;
      }
    
      input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
    
      .slider.round:before {
        border-radius: 50%;
      }
    }
  
    &.right {
      .form-label {
        order: 2;
        margin-left: 1rem;
        margin-right: 0;
      }
      .switch {
        order: 1;
      }
    }
  }
}

