.wrapper {
    .signature {
        height: 35vh;

        .btn-clear {
            position: absolute;
            top: 0.5rem;
            right: 1rem;
        }

        .input-body {
            width: 50%;
            @media screen and (max-width: 420px) {
                width: 75%;
            }
        }
        .input-wrapper {
            position: absolute;
            width: 50% !important;
            top: 50%;
            @media screen and (max-width: 420px) {
                width: 75% !important;
            }
        }
    
        .signature-input {
            text-transform: capitalize;
            font-family: 'La Belle Aurore', cursive;
            font-size: 36px;
            padding: 0.5rem 1.5rem;
            background: transparent;
            border: none;
            height: 70px;
            width: 90%;
            @media screen and (max-width: 1204px) {
                font-size: 24px;
                height: 50px;
            }
            @media screen and (max-width: 420px) {
                font-size: 16px;
                height:40px;
            }
        }

    }
    .btn-dissable {
        background-color: #EAEAEA !important;
        color: unset !important;
        border: none !important;
    }

}