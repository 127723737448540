.decor-cards {
	background: url(../../assets/images/payment/cards.svg) no-repeat
		calc(100% - 16px) center;
}

.decor-cvc {
	background: url(../../assets/images/payment/csv.svg) no-repeat
		calc(100% - 14px) center;
}

.record-due {
	font-size: 40px;
	margin: 0;
	+ p {
		margin: 0 0 10px;
	}
}

.company-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 78px;
	height: 78px;
	overflow: hidden;
	margin: 0 auto;
	img {
		max-width: 100%;
		max-height: 100%;
	}
}
.wt-tabs {
	display: flex;
	justify-content: space-between;
	padding: 0 8px;
	margin-bottom: 20px;
	button {
		width: calc(50% - 10px);
		border: 1px solid #ccc;
		border-radius: 5px;
		background: #fff;
		font-size: 12px;
		line-height: 48px;
		text-transform: uppercase;
		color: #171e23;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		img {
			margin-left: 5px;
			width: 24px;
		}
	}
	.active {
		background-color: #007cff;
		border-color: #007cff;
		color: white;
	}
}

.tab-wisetack {
	padding-top: 20px;
	.wt-logo{
		text-align: center;
	}
	.wt-headline{
		font-size: 18px;
		line-height: 24px;
		margin: 30px 0;
	}
	.wt-info{
		p + p {
			margin-bottom: 20px;
		}
	}
	.wt-cta{
		.btn{
			width: 100%;
		}
	}
	.legal{
		font-size: 12px;
		line-height: 16px;
		border-top: 1px solid #f2f2f2;
		margin: 20px 0;
		padding-top: 20px;
	}
}
