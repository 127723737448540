.avatar-name.small {
  width: 40px;
  height: 40px;
  font-size: 1rem;
}

.avatar-name.medium {
  width: 53px;
  height: 53px;
  font-size: 1.25rem;
}

.avatar-name.large {
  width: 65px;
  height: 65px;
  font-size: 1.5rem
}