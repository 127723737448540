.model-wisetack {
	.modal-dialog {
		max-width: 800px;
		.modal-header .close {
			padding: 16px;
			background-size: 14px;
			background-position: center center;
			right: 10px;
			top: 24px;
		}
	}
	.modal-body {
		font-size: 16px;
		line-height: 24px;
		color: #171e23;
	}

	.wt-logo {
		p {
			margin: 12px 0 0;
		}
	}
	.wt-amount {
		margin: 48px 0;
		p {
			margin: 0;
		}
		.price {
			font-size: 28px;
		}
	}
	.wt-headline {
		margin: 48px 0;
		font-size: 20px;
	}
	.wt-info {
		margin: 48px 0;
		p + p {
			margin: 28px 0 0;
		}
	}
	.wt-cta {
		margin: 48px 0;
		.btn {
			letter-spacing: 1px;
			width: 280px;
		}
	}
	.legal {
		font-size: 12px;
		line-height: 16px;
		margin-bottom: 8px;
	}
}
