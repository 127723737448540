.copyright {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.4px;
	p {
		margin: 0;
		+ p {
			margin-top: 8px;
		}
	}
}
