.modal-disclosure {
    .modal-content {
        .modal-header {
            padding-left: 0.25rem;
            .close {
                top: 15%;
            }
            p {
                font-size: 0.875rem;
                margin-bottom: 0;
            }
            .icon-back {
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
            }
        }

        .modal-body {
            .wrapper {
                min-height: 25vh;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .list {
                    height: 20vh;
                    overflow: scroll;
                    overflow-x: hidden;

                    .disclosure__item {
                        width: 80%;
                    }

                    .crop-text {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
            .view {
                margin-left: 1rem;
                font-weight: 600;
                cursor: pointer;
            }
            .btn-agree {
                margin-top: 4rem;
                margin-bottom: 1rem;
                padding: 1rem 10rem;
                text-align: center;
                @media screen and (max-width: 576px) {
                    padding: 1rem 7rem;
                }
            }

            .disclosure__detail {
                min-height: 30vh;
                padding: 1rem 2rem;
                h4 {
                    font-weight: bold;
                    text-transform: uppercase;
                }

                p {
                    white-space: pre-line;
                }
            }
        }

        @media screen and (max-width: 768px) {
            width: 80%;
            margin: 0 auto;
        }
    }
}

.modal-dialog {
    @media screen and (min-width: 768px) {
        max-width: 768px !important;
    }

    @media screen and (min-width: 1024px) {
        max-width: 1024px !important;
    }
}