.form-select-custom {
  background-color: transparent !important;
  border: none !important;
  .dropdown-toggle {
    &::after {
      content: '';
      border: none;
    }
  }
  .dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
  }
}