$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

$gray-800: #343a40;
$gray-900: #2A2A2A;
$black:    #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

// scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #FF0000 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28A038 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
$primary:       #007AFF;
$bright-blue: #23BBFF;
$dim:     #696969;
$secondary:     #353535;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #AAAAAA;
$gray-light:    $gray-300;
$dark:          $gray-900;

$theme-colors: (
  "bright-blue": $bright-blue,
  "gray-light": $gray-light,
  "gray": $gray-600,
  "form":  #EAEAEA,
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
);

$body-bg: #f7f8f9;
$body-color: $dark;

$link-decoration: none;
$border-radius: .25rem;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-family-sans-serif:      'Source Sans Pro', sans-serif;

// form
$input-btn-padding-y:         .6rem !default;
$input-btn-padding-x:         1rem !default;
$form-label-font-size:        .875rem !default;
$form-label-font-weight:      400 !default;

$form-feedback-font-size: .75rem;

// button
$btn-font-size: .75rem;
$btn-font-weight: 900;
$btn-padding-y: 1rem;

// Modals
$modal-dialog-margin:               0;
$modal-content-border-width:        0;
$modal-sm:                          300px !default;
$modal-md:                          500px !default;
$modal-lg:                          963px !default;
$modal-xl:                          1140px !default;

$border-radius-lg:            .3rem !default;
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$tooltip-border-radius:             $border-radius-lg !default;
$line-height-base:            1.25 !default;
