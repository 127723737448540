.form-tabs {
  &.form-group {
    display: flex;
    padding: .25rem !important;
    border: none !important;
    &:focus-within {
      background-color: rgba(#696969, 0.12) !important;
      border: none;
    }
    .form-check {
      flex-grow: 1;
      padding-left: 0;
      margin-bottom: 0;
      .form-check-input {
        opacity: 0;
        display: none;
        margin-left: 0;
      }
      .form-check-label {
        justify-content: center;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        height: 100%;
        padding: .25rem;
      }
      
      .form-check-input + .form-check-label {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .form-check-input:checked + .form-check-label {
        background-color: #ffffff;
        color: #212529;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          
        }
      }
    }
  }
}