@import '../../assets/styles/base';

.filter-date-range {
  display: grid;
  grid-template-columns: calc(33.33% - 0.375rem) calc(66.66% - 0.375rem);
  grid-gap: 0.75rem;

  .date-range {
    display: grid;
    grid-template-columns: calc(50% - 0.875rem - 0.375rem) 1rem calc(50% - 0.875rem - 0.375rem);
    grid-gap: 0.75rem;

    span {
      align-self: end;
      line-height: 45px;
      color: #929292;
    }
    .flatpickr {
      background: $white;
      margin-bottom: 0;
      border-radius: 4px;
      color: hsl(0,0%,20%);
      font-size: 14px;
      height: 2.65rem !important;
      width: 100% !important;
      @media screen and (max-width: 400px) {
        font-size: 13px;
      }
    }
  }
}

.flatpickr-calendar {
  @media screen and (max-width: 600px) {
    right: 5px !important;
    left: 5px !important;
    width: auto;
    text-align: center;
  }
  .flatpickr-innerContainer {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
  }
  &::before,
  &::after {
    display: none;
  }
}