.block-search {
    position: relative;

    .icon {
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        top: 70%;
        left: 1.625rem;
        width: 1rem;
        height: 1rem;
        transform: translate(-50%, -50%);
        z-index: 10;
    }

    .form-input {
        padding-left: 3.0rem;
    }
}