@import '../../assets/styles/base';

.fluid.dropdown {
  .dropdown-toggle {
    width: 100%;
    span {
      flex-grow: 1;
      text-align: left;
    }
  }
  .dropdown-menu {
    width: 100%;
    min-width: unset;
    max-height: 300px;
  }
}

.default.dropdown {
  .dropdown-toggle {
    background-color: transparent !important;
    border-color: none !important;
    color: $body-color;
    display: flex;
    font-weight: 600
  }
  .dropdown-menu {
    transform: translate(0px, 45px) !important;
  }
}

.light.dropdown {
  .dropdown-toggle {
    background-color: $white !important;
    border-color: $white !important;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05) !important;
    color: $body-color !important;
    display: flex;
    font-weight: 600
  }
  .dropdown-menu {
    transform: translate(0px, 45px) !important;
  }
}

.dropdown.medium {
  .dropdown-toggle {
    max-height: 45px;
  }
  .dropdown-menu {
    transform: translate(0px, 50px) !important;
  }
}

@import './selectSearch';