.modal-reject {
    .modal-content {
        .modal-header {
            padding-left: 0.25rem;
            border-bottom: 1px solid #dee2e6;
            .close {
                top: 15%;
            }
        }

        .modal-body {
            .wrapper {
                padding: 0 3rem 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        @media screen and (max-width: 768px) {
            width: 80%;
            margin: 0 auto;
        }
    }

}