.form-check-label {
  padding-left: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
}
.form-check-label input {
  opacity: 0;
  z-index: 1;
  height: 1rem;
  width: 1rem;
  margin-top: -0.1rem;
  top: 50%;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
  &:disabled {
    opacity: 0;
  }
}

.form-check-label .form-checkbox {
  position: relative;
  
  &::before {
    position: absolute;
    border-radius: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    left: -1.25rem;
    content: "";
    background-color: #ffffff;
    border: 1px solid #C2C2C2;
    
  }
  &.small {
    padding-left: .25rem;
    &::before {
      height: 1rem;
      width: 1rem;
    }
  }
  &.medium {
    padding-left: .5rem;
    &::before {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
  &.large {
    padding-left: .75rem;
    &::before {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}


.form-check-label .form-check-input:checked ~ .form-checkbox {
  &::before {
    background-color: $primary;
  }
  &::after {
    top: 50%;
    transform: translateY(-50%);
    background: no-repeat 50% / 50% 50%;
    position: absolute;
    left: -1.25rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    content: "";
  }
  &.small {
    &::after {
      height: 1rem;
      width: 1rem;
    }
  }
  &.medium {
    &::after {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
  &.large {
    &::after {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}