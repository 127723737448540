@import './mixins';

::-webkit-scrollbar {
  // display: none;
}

// logo
.w-logo {
  max-width: 17.5rem;
  width: 100%;
}

// font-weight
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

// font-size
.fs-sub {
  font-size: $sub-sup-font-size;
}

.fs-small {
  font-size: $small-font-size;
}

.fs-base {
  font-size: 1rem;
}

// font-size
.fs-min {
  font-size: 0.625rem;
}

.border-radius-sm {
  border-radius: 1.75rem;
}

.btn-primary, .btn-primary:disabled {
  color: $white;
  &:hover,
  &:focus,
  &:disabled {
    color: $white;
  }
}

.btn {
  &:focus {
    box-shadow: unset !important;
  }
}

.rotated-45 {
	transform: rotate(45deg);
}
.rotated-90 {
	transform: rotate(90deg);
}
.rotated-180 {
	transform: rotate(180deg);
}
.cursor-move {
  cursor: grab;
}

.text-deposit-due {
	color: #E80604;
}

.text-deposit-paid {
	color: #0A891B;
}

.text-legend {
  font-size: $sub-sup-font-size;
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.text-sub {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.875rem;
  margin-bottom: 1rem;
}
.loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 900000;
  background-color: #e9e9e9;
  opacity: 0.5;

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    z-index: 900000;
    border: 5px solid #f3f3f3;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
  }
}

.disabled {
  opacity: .5;
}

.overflow-none {
	overflow: unset !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.modal {
  outline: 0;
	.modal-header {
		display: unset !important;
		text-align: center;
		position: relative;
		.close {
			position: absolute;
			padding: .5rem;
			top: 50%;
			right: 0;
			background-color: transparent;
			background-repeat: no-repeat;
			border: none;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.79 1.06L12.73 0L6.895 5.835L1.06 0L0 1.06L5.835 6.895L0 12.73L1.06 13.79L6.895 7.955L12.73 13.79L13.79 12.73L7.95499 6.895L13.79 1.06Z' fill='%232A2A2A'/%3E%3C/svg%3E%0A");

			transform: translateY(-50%);
			background-size: 100%;
			[aria-hidden="true"] {
				display: none;
			}
		}
	}
}

.dropdown-toggle {
  display: inline-flex;
  align-items: center;
  @include chevron($black, .75rem);
}

.radio-dropdown {
  .dropdown-item:active,
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: unset;
    color: unset;
  }
}

.dropdown-item {
  &.active {
    background-color: transparent;
    color: $primary !important
  }
}

input {
  &:focus {
    border-color: unset;
    box-shadow: unset !important;
  }
  &:focus-visible {
    outline: unset;
  }
}

.overflow-auto {
  &::-webkit-scrollbar {
    display: block;
  }
	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
		width: 3px;
		border-radius: 3px;
	}
	&::-webkit-scrollbar {
		background-color: white;
	}
	&::-webkit-scrollbar-thumb {
		background-color: white;
	}
}
.overflow-auto:hover {
	&::-webkit-scrollbar {
		background-color: $gray-200;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $gray-400;
	}
}
.table-layout-fixed {
  table-layout: fixed !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: $dark;
  opacity: .5
}

// display grid
.d-grid {
  display: grid;
}

.grid-auto-columns {
  grid-auto-columns: 1fr;
}

.grid-gap-0 {
  grid-gap: 0
}

.grid-gap-1 {
  grid-gap: 0.25rem
}

.grid-gap-2 {
  grid-gap: 0.5rem
}

.grid-gap-3 {
  grid-gap: 1rem
}

.grid-gap-4 {
  grid-gap: 1.5rem
}

.grid-gap-5 {
  grid-gap: 3rem
}

.grid-template-areas-2 {
  grid-template-areas:
    'item item';
}

.grid-template-areas-3 {
  grid-template-areas:
    'item item item';
}

.grid-template-areas-4 {
  grid-template-areas:
    'item item item item';
}

.grid-template-areas-5 {
  grid-template-areas:
    'item item item item item';
}

.wisetack-link{
	color: #171E23;
	margin-top: 12px;
	font-size: 14px;
	line-height: 20px;
	.link{
		display: inline-block;
		margin-left: 5px;
		font-weight: 600;
		color: #307FE2;
		text-decoration: underline;
	}
}

.mr-1 {
  margin-right: .5rem;
}

.ml-1 {
  margin-left: .5rem;
}

.text-assign-to-grey {
  color: #000000;
  opacity: 0.5;
}

.text-assign-to-red {
  color: #E80604;
}
.mt-40 {
  margin-top: 10rem;
}

.bg-green {
  background-color: #28A038;
}

.rounded-4xl {
  border-radius: 2rem !important;
}

.font-weight-bold {
  font-weight: bold;
}

@media screen and (max-width: 620px) {
  .Toastify__toast-container {
    width: 90% !important;
  }
}

@media screen and (max-width: 480px) {
  .Toastify__toast-container {
    top: 1.25rem;
    left: 2rem;
  }
}
