.workflow-desktop {
    margin-top: 3rem;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 33%;
    overflow-x: scroll;
    overscroll-behavior-inline: contain;

    &::-webkit-scrollbar {
        border-radius: 12px;
        height: 8px;
        border-color: lightgray;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background-color: gray;
    }

    .column {
        padding: 0 1.5rem;
        height: 78vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            border-radius: 12px;
            width: 8px;
            background-color: lightgray;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            background-color: gray;
        }

        &:first-child {
            padding-left: 0;
        }

        .title {
            border-radius: 4px;
            padding: 14px 24px;
            font-weight: bold;
            position: sticky;
            position: -webkit-sticky;
            text-transform: uppercase;
            color: #fff;
            top: 0;
            z-index: 10;
            .input-header {
                border: unset;
                background-color: unset;
                color: unset;
            }
        }

        .workflow-item {
            padding: 1rem;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin: 1rem 0;
            .workflow-image {
                align-self: center;
                position: relative;

                .crop {
                    height: 110px;
                    overflow: hidden;
                    .crop-image {
                        width: auto;
                        height: 140px;
                      }
                    .non-crop-image {
                        width: 100%;
                        height: 130px;
                    }
                }
                .icon-group {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: transparent !important;
                    border: none !important;
                    img {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            .vehicle-info {
                position: relative;

                .workflow-total {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                }
            }


            .workflow-services {
                border-top: 1px solid #E5E5E5;
                margin-top: 1rem;
                padding-top: 1rem;
            }
        }

        .crop-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.workflow-mobile {
    margin-top: 1rem;

    .column {
        padding: 0 1.5rem;
        height: 78vh;
        overflow-y: scroll;
        &:first-child {
            padding-left: 0;
        }

        .title {
            border-radius: 4px;
            padding: 14px 24px;
            font-weight: bold;
            position: sticky;
            position: -webkit-sticky;
            text-transform: uppercase;
            color: #fff;
            top: 0;
            z-index: 10;
            .input-header {
                border: unset;
                background-color: unset;
                color: unset;
            }
        }

        .workflow-item {
            padding: 1rem;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin: 1rem 0;
            .workflow-image {
                align-self: center;
                position: relative;

                .crop {
                    height: 95px;
                    overflow: hidden;
                    .crop-image {
                        width: auto;
                        height: 120px;
                      }
                    .non-crop-image {
                        width: 100%;
                        height: 100px;
                    }
                    
                }
                .icon-group {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: transparent !important;
                    border: none !important;
                    img {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            .vehicle-info {
                position: relative;

                .workflow-total {
                    position: absolute;
                    top: 0;
                    right: 1rem;
                }
            }


            .workflow-services {
                border-top: 1px solid #E5E5E5;
                margin-top: 1rem;
                padding-top: 1rem;
            }
        }

        .crop-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.workflow-null-data {
    background-color: #E6F4E6;
    width: 73%;
    margin-top: 1.5rem;
    padding: 0.7rem 1rem;
    border-radius: 2px;

    @media screen and (max-width: 576px) {
        width: 100%;
    }
}

.fs-small {
    font-size: 0.875rem;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .workflow-desktop {
        overflow-x: scroll;
        grid-auto-columns: unset;

        .column {
            border-right: unset;
            width: 344px;
        }
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .workflow-desktop {
        grid-auto-columns: unset;

        .column {
            border-right: unset;
            width: 344px;
        }
    }
}

.workflow {
    &.form {
        .form-group {
            background: #FFFFFF;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            padding: 10px 16px;
            border-radius: 4px;
            margin-bottom: 0;
        }
    }
}