@import 'bootstrap';
@import 'custom';

@import '~flatpickr/dist/flatpickr.css';
@import '~react-toastify/dist/ReactToastify.css';
@import './toast';


html {
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
  }
  // @media screen and (max-width: 350px) {
  //   font-size: 12px !important;
  // }

  scroll-behavior: smooth;
}
