.riband {
	letter-spacing: 1px;
	position: relative;
	.button-close {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
	}
	.question {
		font-size: 12px;
		line-height: 16px;
		font-weight: bold;
	}
	button {
		letter-spacing: 1px;
	}
}
