@import '../../assets/styles/base';

.primary.table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  table-layout: auto;
  th {
    vertical-align: middle;
    border-bottom: unset;
    color: rgba(53, 53, 53, 0.5);
    font-size: 0.875rem;
    font-weight: 600;
    padding: .5rem;
    text-transform: uppercase;
    background-color: $body-bg;
    z-index: 11;
    vertical-align: bottom;
  }
  tbody {
    tr {
      box-shadow: 0 0.5rem 1rem 0 rgba(#000000, 0.05);
    }
    td {
      padding: .5rem;
      vertical-align: middle;
      background-color: $white;
    }
  }
}